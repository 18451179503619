import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { CrossStorageClient } from 'cross-storage'
import { startSession, loginCA } from '../../actions/auth.action'
import { loading } from 'actions/loading.action'
import { AUTH_CLIENT_HOST_URL } from 'lib/centralizedAuthUrls'
import { getProfilePicSignedUrl } from 'actions/getSignedUrls';
import { Row } from 'react-bootstrap'
import { HiArrowRight } from 'react-icons/hi'

import ImageCornerBadge from '../../res/img/login-corner-badge.png'
import ImageHero from '../../res/img/login-hero.png'
import ImageLogo from '../../res/img/reach52-beta-logo.png'
import { useState } from 'react'
import toast from 'react-hot-toast'
import ReCAPTCHA from 'react-google-recaptcha'
import useTitle from 'hooks/useTitle'

const Login = ({
  startSession,
  loading,
  isLoading,
  user,
  localLoginStatus,
  getProfilePicSignedUrl,
  loginCA,
  signedUrl: {profilePicSignedUrl}
}) => {

  useTitle('Login')

  const navigate = useNavigate()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const recaptchaRef = useRef();

  useEffect(() => {
    switch (localLoginStatus) {
      case 'success':
        toast.success('You have successfully logged in!')
        break;
      case 'no_privillege':
        toast.error('You don\'t have enough permissions to access this application')
        break;
      case 'invalid_captcha':
        toast.error('Captcha validation failed! Please try again')
        break;
      case 'invalid_credential':
        toast.error('Invalid credentials! Please try again')
        break;
      default:
        break;
    }
  }, [localLoginStatus])
  

  useEffect(() => {
    const validateSession = async () => {
      if(user?.userRole?.includes('app-external-user')){
        navigate('/dashboard')
      } else {
        loading({message: 'We\'re getting your Insights set up, please wait 🙂', isLoading: true, isLoadingImage: true})
        const storage = new CrossStorageClient(AUTH_CLIENT_HOST_URL, {
          timeout: 10000,
          frameId: 'storageFrame',
        })
        let token = null
        await storage
          .onConnect()
          .then(async () => {
            const session = JSON.parse(await storage.get('session'))
            const user = JSON.parse(await storage.get('user'))
            const isSessionActive = session.insights.isActive
            if (isSessionActive) {
              token = session.token
              const userId = session.userId
              const appId = session.insights.appId
              if (token) {
                localStorage.setItem('token', token)
                localStorage.setItem('user', JSON.stringify(user))
                await getProfilePicSignedUrl({region: user.country.iso3==='idn'?"IDN":"IND", imageName: user.profilePic, token: token, userRole: '' })
                await startSession(token, userId, appId, '', navigate)
              }
            }
          })
          .catch(function (err) {})
          .finally(()=>{
            loading({message: '',isLoading: false, isLoadingImage: false})
          })
      }
    }
    if(localStorage.getItem('skipSessionCheckOnce') !== true){
      localStorage.removeItem('skipSessionCheckOnce')
      validateSession()
    }
  }, [startSession, loading, navigate, getProfilePicSignedUrl, user])

  const login = async (e) => {
    e.preventDefault()
    const reCaptchaToken = await recaptchaRef.current.executeAsync()
    await loginCA(username, password, reCaptchaToken, navigate)
    recaptchaRef.current?.reset();
  }

  return isLoading || <div className='container-fluid'>
    <Row className='row min-vh-100 login-screen'>
      <div className='col-12 col-lg-8 left-bar d-flex align-items-center justify-content-center d-none d-lg-block'>
      <div className="absolute top-0 right-0 p-4 text-white">
          uat 1.1
      </div>
        <div className='corner-badge'>
          <img src={ImageCornerBadge} alt="background"/>
        </div>
        <div className='content-wrapper'>
          <h2 className='text-center heading'>
            Redesigning healthcare<br/>
            to <span>reach 52%</span> of the world
          </h2>
          <div className='text-center py-5'>
            <a className='btn btn-outline-light px-4 d-inline-flex align-items-center' href='/expand-campaign'>
              <span className="fw-bold">Explore a new campaign with us!</span>
              <HiArrowRight className='ms-2'/>
            </a>
          </div>
          <div className='text-center py-3'>
            <img src={ImageHero} alt="hero" className='hero-image'/>
          </div>
        </div>
      </div>
      <div className='col-12 col-lg-4 right-bar d-flex flex-column align-items-center justify-content-between'>
        <div className='d-flex align-items-center flex-column w-100'>
          <div className='my-5 py-5'>
            <img src={ImageLogo} className="logo mt-4" alt='Logo'/>
          </div>
          <form className='form-row' onSubmit={login}>
            <div className='form-group mb-4'>
              <label className='mb-1 fw-bold'><small>Username</small></label>
              <input type='text' required value={username} onChange={e => setUsername(e.target.value)} className='form-control'/>
            </div>
            <div className='form-group mb-4'>
              <label className='mb-1 fw-bold'><small>Password</small></label>
              <input type='password' required value={password} onChange={e => setPassword(e.target.value)} className='form-control'/>
            </div>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            />
            <div className='text-center mt-5'>
              <button type='submit' className='btn w-100 btn-primary' disabled={localLoginStatus === 'requesting'}>
                <small className='fw-bold'>Login</small>
                <HiArrowRight className='ms-3'/>
              </button>
            </div>
            <div className='d-flex justify-content-evenly mt-3 align-items-center'>
              <a href='/terms-and-conditions'><small>Terms & Conditions</small></a>
              <a href='/privacy-policy'><small>Privacy Policy</small></a>
            </div>
          </form>
        </div>
        {/* Footer */}
        <div className='text-center text-muted w-100 py-3 opacity-50'>
          {/* <small>Thanks for being a part of our beta programme</small> */}
        </div>
      </div>
    </Row>
  </div>

}

Login.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  localLoginStatus: PropTypes.string,
  getProfilePicSignedUrl: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.loading.isLoading,
  localLoginStatus: state.auth.localLoginStatus,
  signedUrl: state.signedUrl,
  user: state.auth.user,
  startSession
})
export default connect(mapStateToProps , { startSession, loginCA, loading, getProfilePicSignedUrl })(Login)